
import { Component, Prop, Vue } from 'vue-property-decorator';
import { copySelectElementContents, copySelectTextContents } from '@mobro/libs';
import { ISentenceItem } from '../type';
import PreviewNewsTitleItem from './PreviewNewsTitleItem.vue';
import PreviewNewsImageItem from './PreviewNewsImageItem.vue';
import PreviewNewsDespItem from './PreviewNewsDespItem.vue';
import PreviewNewsSplitLine from './PreviewNewsSplitLine.vue';
import PreviewNewsBanner from './PreviewNewsBanner.vue';
import PreviewNewsMiniprogram from './PreviewNewsMiniprogram.vue';
import { Iobject } from '@mobro/libs/es/type';
import { Message } from 'element-ui';
import MorningPicture from '../MorningPicture/index.vue';

@Component({
    components: {
        PreviewNewsTitleItem,
        PreviewNewsImageItem,
        PreviewNewsDespItem,
        PreviewNewsSplitLine,
        PreviewNewsBanner,
        PreviewNewsMiniprogram,
        MorningPicture,
    },
})
export default class ArticlePreview extends Vue {
    @Prop() posterUrl!: string;
    @Prop() posterWhiteUrl!: string;
    @Prop() showPreview!: boolean;
    @Prop() newsItemList!: ISentenceItem[];
    @Prop() dateStr!: string;
    @Prop() formData!: Iobject;
    @Prop() picture!: string;
    @Prop() morning!: string;
    @Prop() activityId!: string;

    onCopyImgPoster(e: Event) {
        if (e.target && (e.target as HTMLImageElement).tagName === 'IMG') {
            copySelectTextContents((document.querySelector('#posterPreview') as HTMLElement)?.innerHTML);
            Message.success('海报复制成功~~~');
        }
    }
    onCopyArticle() {
        copySelectElementContents(document.querySelector('#articleContent') as HTMLElement);
        Message.success('文章复制成功~~~');
    }

    onClose() {
        this.$emit('update:showPreview', false);
    }
}
